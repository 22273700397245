.cookies {
  position: fixed;
  right: 7vw;
  bottom: 35px;
  width: 100%;
  max-width: 540px;
  max-height: 360px;
  z-index: 9999;
  @include MQ(Lneg) {
    right: 0;
    bottom: 0;
    max-height: unset;
    max-width: unset;
  }
  .cookies-alert {
    background: transparentize($secondary, .3);
    padding: 25px;
    display: none;
    .cookies-alert-header {
      @include MQ(Lneg) {
        img {
          width: 21px;
          display: block;
          margin: 0 auto;
        }
      }
    }
    .cookies-alert-body {
      margin: 15px 0;
      p {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5;
        color: $white;
        @include MQ(Lneg) {
          font-size: 1rem;
        }
        &:first-child {
          margin-bottom: 15px;
        }
        a {
          color: $white;
          text-decoration: underline;
        }
      }
    }
    .cookies-alert-footer {
      button {
        font-size: 1.4rem;
        padding: 7px 5px;
        color: $white;
        position: relative;
        transition: .2s all ease-in-out;
        z-index: 1;
        @include MQ(Lneg) {
          font-size: 1.2rem;
          display: block;
          margin: 0 auto;
        }
        &:hover {
          color: $secondary;
          &:after {
            height: 100%;
            z-index: -1;
          }
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: $white;
          transition: .2s all ease-in-out;
        }
      }
    }
  }
}