article.section-scroll {
    &.active {
        .block-content-box {
            animation: flyInText 2s forwards;
            animation-delay: 0;
        }
    }
}

.main-view {
    padding: 0;
    height: 100vh;
    z-index: 0;
    &.animate {
        .group {
            animation: flyInImage 2s ease forwards;
            animation-delay: 0;
            display: block;

            .main-view-title {
                animation: slideFromLeft 2s ease forwards;
                animation-delay: .8s;
                display: block;
            }
            .main-view-menu {
                animation: slideFromRight 2s ease forwards;
                animation-delay: .8s;
                display: block;
                margin-left: auto;
            }
        }
    }


    @include MQ(Lneg) {
        margin-bottom: -125px;
    }
    @media screen and (max-width: 768px) and (max-device-width: 768px) and (orientation: landscape) {
        margin-bottom: 0px;
    }
    .group {
        background-image: url('../img/background/banner-primary@2x.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include MQ(XLneg) {
            background-position-x: 75%;
        }

        @include MQ(Lneg) {
            &.banner-tween {
                transform: scale(1);
                opacity: 1;
            }
        }

        &>div {
            padding: 0 25px;

            @include MQ(Lneg) {
                padding: 0;
            }
        }

        @include MQ(Lneg) {
            height: 100%;
            background-image: url('../img/background/banner-primary-mobile@2x.png');
            display: flex;
            flex-direction: column;
        }

        .main-view-header {
            width: 100%;
            height: 100px;
            padding: 0;

            @include MQ(Lneg) {
                height: 64px;
            }

            .page-header {
                width: 100%;
                max-width: $XLS;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 auto;
                padding: 25px;

                &>div {
                    flex: 1 1 0;
                    text-align: center;

                    &:first-of-type {
                        text-align: left;
                    }

                    &:last-of-type {
                        text-align: right;
                    }
                }

                &>.mobile-nav {
                    .bars {
                        background: none;

                        .hamburger {
                            &.is-active {
                                .hamburger-inner {
                                    transform: translate3d(0, 0, 0) rotate(45deg);

                                    &:after {
                                        transform: translate3d(0, 7px, 0) rotate(-90deg);
                                    }
                                }
                            }

                            &-box {
                                width: 32px;
                                height: 17px;

                                .hamburger-inner {
                                    background-color: $secondary;
                                    width: 28px;
                                    height: 2.5px;

                                    &:before,
                                    &:after {
                                        width: 28px;
                                        height: 2.5px;
                                        background-color: $secondary;
                                    }

                                    &:before {
                                        top: 7px;
                                    }

                                    &:after {
                                        top: -7px;
                                    }
                                }
                            }

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }

                .product {
                    @include MQ(Lneg) {
                        img {
                            width: 105px;
                        }
                    }
                }

                .brand {
                    @include MQ(Lneg) {
                        margin-top: -5px;

                        img {
                            width: 36px;
                        }
                    }
                }
            }
        }
    }

    .main-view-row {
        display: flex;
        justify-content: space-between;
        max-width: $XLS;
        margin: 0 auto;

        @include MQ(XXLplus) {
            max-width: unset;
        }

        @include MQ(Lneg) {
            margin: 0;
        }

        >div {
            width: 100%;
        }
    }

    .main-view {
        &-header {
            height: 100px;

            @include MQ(Lneg) {
                height: 84px;
            }
        }

        &-body {
            align-items: center;
            height: calc(100vh - 300px);

            @include MQ(MAXneg) {
                height: calc(100vh - 250px);
            }

            @include MQ(Mneg) {
                padding: 20px;
                margin-top: auto;
                height: unset;
            }
        }

        &-footer {
            height: 200px;
            align-items: flex-start;

            @include MQ(MAXneg) {
                height: 150px;
            }

            @include MQ(Lneg) {
                margin-top: 54px;
                height: auto;
                margin-bottom: 125px;
            }

            @media screen and (max-width: 768px) and (max-device-width: 768px) and (orientation: landscape) {
                height: auto;
            }
        }
    }

    &-title {
        padding-left: 25px;
        @include MQ(XLSneg) {
            padding-left: 0;
        }
        &-text {
            h1 {
                line-height: 1.2;
                font-size: 5rem;
                @include fluid-type(992px, 1600px, 28px, 50px);

                @include MQ(Lneg) {
                    @include fluid-type(360px, 992px, 35px, 64px);
                    padding: 0 25px;
                }

                @media screen and (max-width: 768px) and (max-device-width: 768px) and (orientation: landscape) {
                    @include fluid-type(360px, 768px, 28px, 35px);
                }

                .green {
                    color: $primary;
                }
            }
        }

        &-scrollr {
            margin-bottom: 5px;

            @include MQ(Lneg) {
                text-align: center;
            }

            a {
                font-size: 2rem;
                color: $secondary;
                padding: 10px;
                position: relative;
                display: inline-flex;
                align-items: center;

                @include fluid-type(1024px, 1200px, 16px, 20px);
                @include fluid-type(992px, 1024px, 16px, 16px);

                @include MQ(Lneg) {
                    font-size: 1.6rem;
                }

                .arrow-down {
                    margin-left: 10px;
                    padding-top: 5px;
                    transition: .3s all ease;
                    @include MQ(XLneg) {
                        img {
                            transform: scale(.7);
                        }
                    }
                    @include MQ(Lneg) {
                        img {
                            transform: scale(1);
                        }
                    }
                }

                &.app-mobile {
                    @include MQ(Lneg) {
                        .scrollr-text {
                            display: none;
                        }

                        .arrow-down-mobile {
                            img {
                                width: 28px;
                            }
                        }
                    }

                    @include MQ(Mneg) {
                        .arrow-down-mobile {
                            img {
                                width: 100%;
                            }
                        }
                    }
                }

                &:hover {
                    .arrow-down {
                        transform: translateY(5px);
                    }
                }
            }
        }
    }

    &-menu {
        width: 100%;
        max-width: 480px;
        @include MQ(XLSneg) {
            padding-right: 0;
            max-width: 420px;
        }
        @include MQ(MAXneg) {
            max-width: (100 - (100 / 1.65) * 1%);
        }
        a {
            display: flex;
            justify-content: flex-end;
            color: $secondary;
            font-size: 1.6rem;
            position: relative;
            padding: 0 75px 0 0;

            &:hover {
                color: $primary;
            }
        }

        .large {
            ul {
                li {
                    position: relative;

                    &:last-child {
                        a {
                            .text:after {
                                display: none;
                            }
                        }
                    }

                    a {
                        font-size: 3rem;
                        line-height: 1.2;
                        position: relative;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding: 0;
                        margin-left: auto;

                        @include MQ(XLSneg) {
                            @include fluid-type(1200px, 1600px, 21px, 30px);
                        }

                        @include MQ(XLneg) {
                            width: 100%;
                            margin-left: 0;
                        }

                        .icon {
                            width: 100px;
                            margin: 0 15px;

                            img {
                                width: auto;
                                height: 64px;
                                display: block;
                                margin: auto;
                                @include MQ(XLneg) {
                                    height: 48px;
                                }
                            }
                        }

                        .text {
                            width: 100%;
                            position: relative;
                            padding: 25px 0;

                            &:after {
                                content: '';
                                width: 100%;
                                height: 2px;
                                position: absolute;
                                top: 100%;
                                left: 0;
                                background: $primary;
                            }
                        }
                    }
                }
            }
        }

        .small {
            ul {
                li {
                    margin-bottom: 5px;

                    a {
                        font-size: 2rem;
                        position: relative;
                        margin-left: auto;                        
                        @include fluid-type(1200px, 1600px, 16px, 20px);    

                        &:after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            transform: translateY(-50%);
                            width: 30px;
                            height: 2px;
                            background: $secondary;
                        }
                    }
                }
            }
        }
    }
}



.app-view-page--one {
    z-index: 1;
    position: relative;

    .radius-tween {
        @include MQ(Lneg) {
            border-top-left-radius: 43px;
            border-top-right-radius: 43px;
        }
    }
}

.page-view-container {
    position: relative;

    .split-banner-caption {
        opacity: 0;
        visibility: hidden;
        transform: translateY(100vh);
        display: none;
        @include MQ(Lneg) {
            display: block;
            opacity: 1;
            visibility: visible;
            transform: none;
        }
    }
    .navbar-wrapper {
        display: none;
    }


    &.animate {
        // Left Split Col
        .split-banner-image {
            animation: flyInImage 2s ease forwards;
            animation-delay: 0;
            display: block;
            @include MQ(Lneg) {
                animation: none;
            }
        }
        .split-banner-caption {
            animation: flyInText 2s ease forwards;
            animation-delay: 0;
            display: block;
            @include MQ(Lneg) {
                animation: none;
            }
        }
        .navbar-wrapper {
            animation: flyInText 2s ease forwards;
            animation-delay: 0;
            display: block;
            @include MQ(Lneg) {
                animation: none;
            }
        }

        // Right Split Col
        article {
            animation: flyInText 2s ease forwards;
            animation-delay: 0;
            @include MQ(Lneg) {
                animation: none;
            }
        }
        // Menu
        .menu-box {
            animation: barsFadeIn 2s ease forwards;
            animation-delay: 0;
            @include MQ(Lneg) {
                animation: none;
            }
        }
    }

    .menu-box {
        height: 100vh;
        position: absolute;
        top: 0 !important;
        left: 50%;
        z-index: 9999;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        
        @include MQ(Lneg) {
            display: none;
        }
    }
}