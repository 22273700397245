.page-view-container {
    position: relative;
    overflow: hidden;
    .view-container-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        z-index: 9999;
        @include MQ(Lneg) {
            width: 100%;
            background: $white;
            height: 72px;
            border-top-left-radius: 43px;
            border-top-right-radius: 43px;
            &.menu-tween {
                opacity: 0;
                transform: translateX(200px);
                backface-visibility: hidden;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        
        .header-brand {
            width: 100%;
            .brand-logo {
                padding: 25px;
            }
            .d-header {
                width: calc(100vw - 15px);
                display: flex;
                align-items: center;
                justify-content: space-between;
                .logo {
                    padding-right: 25px;
                }
            }
            .mobile-header {
                padding: 0 25px;
                display: none;
                @include MQ(Lneg) {                    
                    display: flex;
                    align-items: flex-start;
                    padding: 0;
                }
                & > div {
                    flex: 1 1 0;
                    text-align: center;
                    align-self: center;
                    &:first-child {
                        text-align: left;
                        img {
                            width: 105px;
                        }
                    }
                    &:last-child {
                        margin-top: -5px;
                        text-align: right;
                        img {
                            width: 36px;
                        }
                    }
                }
                & > .menu {
                    .bars {
                        background: none;
                        .hamburger {
                            &.is-active {
                                .hamburger-inner {
                                    transform: translate3d(0,0,0) rotate(45deg);
                                    &:after {
                                        transform: translate3d(0,7px,0) rotate(-90deg);
                                    }
                                }
                            }
                            &-box {
                                width: 32px;
                                height: 17px;
                                .hamburger-inner {
                                    background-color: $primary;
                                    width: 28px;
                                    height: 2px;
                                    &:before, &:after {
                                        width: 28px;
                                        height: 2px;
                                        background-color: $primary;
                                    }
                                    &:before {top:7px;}
                                    &:after {top:-7px;}
                                }
                            }
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}