.page-view-container {
    position: relative;
    color: $secondary;
    width: 100%;
    min-height: 100vh;
    background: $white;

    @include MQ(Lneg) {
        padding-top: 72px;
    }

    .view-container-body {
        height: 100%;

        .container-split {
            height: 100%;
            background: $white;
            display: flex;
            justify-content: space-between;

            @include MQ(Lneg) {
                display: block;
            }
        }

        .split-block-banner {
            flex: 1 1 0;
            position: relative;
            height: 100vh;

            @include MQ(Lneg) {
                height: 400px;
            }

            &.unique-banner {
                @include MQ(Lneg) {
                    height: 150px;
                    margin-top: 72px;
                }
            }

            .split-banner-wrapper {
                width: 100%;
                height: 100%;
            }

            .split-banner-image {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 0;

                @include MQ(Lneg) {
                    background-position: top right;
                }

                &.no-background {
                    background: $white;

                    &:before {
                        display: none;
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    display: block;
                    background: transparentize($secondary, .65);
                }
            }

            .split-banner-caption {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .caption-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: calc(100% - 10vw);
                    height: 100%;
                    position: relative;

                    @include MQ(Lneg) {
                        height: calc(100% - 70px);
                        justify-content: flex-end;
                    }

                    p {
                        color: $white;
                    }

                    .caption-group {
                        display: flex;
                        align-items: flex-start;
                        width: 100%;
                        margin-bottom: 25px;

                        @include MQ(Lneg) {
                            flex-direction: column;
                            align-items: center;
                        }

                        .caption-icon {
                            width: 100px;

                            @include MQ(XLneg) {
                                width: 74px;
                            }

                            img {
                                width: auto;
                                height: 78px;

                                @include MQ(XLneg) {
                                    height: 54px;
                                }

                                @include MQ(Lneg) {
                                    display: block;
                                    margin: 0 auto 15px;
                                }
                            }
                        }

                        .caption-heading {
                            width: 100%;
                            margin-left: 25px;

                            @include MQ(Lneg) {
                                margin-left: 0;
                            }

                            h2 {
                                color: $white;
                                font-weight: 400;
                                font-size: 4.3rem;
                                line-height: 1.3;
                                @include fluid-type(992px, 1200px, 22px, 26px);
                                @include fluid-type(1200px, 1920px, 26px, 43px);

                                &.no-background-text {
                                    color: $secondary;
                                }

                                @include MQ(Lneg) {
                                    font-size: 2.6rem;
                                    text-align: center;
                                }
                            }

                            .caption-links {
                                margin-top: 25px;

                                @include MQ(Lneg) {
                                    display: none;
                                }
                                ul {
                                    width: 360px;

                                    @include MQ(XLneg) {
                                        width: 240px;
                                    }

                                    li {
                                        a {
                                            display: flex;
                                            align-items: center;
                                            width: 100%;
                                            color: $secondary;
                                            font-size: 1.9rem;
                                            padding: 5px 0;
                                            @include fluid-type(1024px, 1200px, 16px, 19px);
                                            @include fluid-type(992px, 1024px, 16px, 16px);

                                            span {
                                                flex-grow: 1;
                                            }

                                            img {
                                                width: 100%;
                                                max-width: 28px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .caption-text {
                        width: calc(100% - 110px);
                        margin-left: auto;

                        @include MQ(XLneg) {
                            width: calc(100% - 84px);
                        }

                        @include MQ(Lneg) {
                            margin-left: 0;
                            width: 100%;
                        }

                        p {
                            width: 90%;
                            font-size: 2rem;
                            line-height: 1.6;
                            @include fluid-type(1200px, 1920px, 16px, 20px);
                            @include fluid-type(992px, 1200px, 14px, 16px);

                            @include MQ(Lneg) {
                                width: calc(100% - 2vw);
                                text-align: center;
                                padding: 0 10px;
                                line-height: 18px;
                                @include fluid-type(360px, 992px, 14px, 16px);
                            }
                        }
                    }

                    .caption-detail {
                        width: calc(100% - 110px);
                        padding: 15px;
                        position: absolute;
                        bottom: 35px;
                        left: 110px;

                        @include MQ(XLneg) {
                            width: 100%;
                            left: 0;
                            padding: 15px 10px;
                        }

                        .small {
                            width: 90%;
                            font-size: 1.4rem;
                            line-height: 1.2;
                            @include fluid-type(1200px, 1920px, 10px, 14px);
                        }
                    }
                }
            }
        }

        .split-block-content {
            flex: 1 1 0;
            position: relative;
            overflow: hidden;

            #float-sidebar {
                z-index: 1;
            }

            .float-sidebar {
                height: 100vh;
                position: absolute;
                top: 0;
                right: 0;

                .sidebar-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    position: relative;
                }

                .navbar {
                    width: 84px;
                    overflow: hidden;
                    height: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .navbar-wrapper {
                        padding: 25px 0;
                        width: 100%;
                        position: relative;
                        &:before {
                            content: '';
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: transparent;
                            visibility: hidden;
                        }
                    }

                    &.navbar-active {
                        width: calc(100% - 3vw);

                        .navbar-wrapper {
                            &:before {
                                background: transparentize($secondary, .2);
                                visibility: visible;
                            }

                            .text {
                                visibility: visible;
                                display: block;
                            }

                            .dash,
                            .dash-active {
                                background: $white !important;
                            }
                        }
                    }

                    ul {
                        padding: 25px;

                        li {
                            position: relative;
                            padding: 0 25px 0 0;
                            height: 48px;
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &:hover {
                                .text {
                                    text-decoration: underline;
                                }
                            }

                            &.current {
                                .text {
                                    color: $white;
                                }
                            }

                            .text {
                                text-align: left;
                                font-size: 1.8rem;
                                line-height: 1.4;
                                width: calc(100% - 4vw);
                                padding-left: 35px;
                                display: none;
                                visibility: hidden;
                                color: #ddd;
                                position: relative;
                                @include fluid-type(1024px, 1200px, 16px, 18px);
                                @include fluid-type(992px, 1024px, 14px, 16px);
                            }

                            .dash {
                                position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translateY(-50%);
                                content: '';
                                display: block;
                                width: 25px;
                                height: 2px;
                                background: $secondary;

                                &-active {
                                    background: $primary;
                                    width: 45px;
                                }

                                @include MQ(XLneg) {
                                    width: 10px;
                                    height: 4px;
                                    border-radius: 24px;

                                    &-active {
                                        width: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @include MQ(Lneg) {
                flex: 0;
            }

            .banner-annotation {
                padding: 40px 25px 0;
                width: calc(100% - 2vw);

                p {
                    &.small {
                        font-size: 1.2rem;
                        line-height: 16px;
                        margin-bottom: -20px;
                    }
                }
            }

            article {
                min-height: 100vh;
                color: $secondary;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 25px 0;
                z-index: 1;

                opacity: 0;
                visibility: hidden;
                @include MQ(Lneg) {
                    opacity: 1;
                    visibility: visible;
                }

                &:last-of-type {
                    padding: 0;
                }

                &.unique-content {
                    @include MQ(Lneg) {
                        padding-top: 0;
                        &:last-of-type {
                            padding-top: 0;
                        }
                    }
                }

                @include MQ(Lneg) {
                    padding: 40px 25px 0;
                    min-height: unset;

                    &:last-of-type {
                        padding: 40px 25px 0;
                    }
                }

                &.green {
                    z-index: 2;
                    background: $primary;

                    @include MQ(Lneg) {
                        padding-top: 100px;
                        padding-bottom: 100px;
                    }

                    .block-content-box {
                        .box-title {
                            h3 {
                                color: $white;
                            }
                        }

                        .box-text {
                            p {
                                color: $white;

                                strong.underline {
                                    text-decoration: none;
                                    padding-bottom: 7px;
                                    display: inline-block;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: 100%;
                                        left: 0;
                                        width: 100%;
                                        height: 2px;
                                        background: $white;
                                    }
                                }
                            }
                        }

                        .box-key {
                            li {
                                color: $white;
                            }
                        }
                    }
                }

                .block-content-box {
                    width: 100%;
                    max-width: 640px;
                    margin: 0 auto;
                    height: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-items: flex-start;
                    flex-direction: column;
                    text-align: left;
                    padding: 50px 0;

                    @include MQ(XLSneg) {
                        max-width: 540px;
                    }

                    @include MQ(XLneg) {
                        max-width: 400px;
                    }

                    @include MQ(Lneg) {
                        max-width: unset;
                        width: calc(100% - 2vw);
                        padding: 25px 0;
                    }

                    .box-title {
                        width: calc(100% - 4vw);

                        @include MQ(XLneg) {
                            width: 100%;
                        }

                        h3 {
                            font-size: 2.6rem;
                            line-height: 1.6;
                            color: $primary;
                            padding: 0 0 15px;

                            span {
                                display: block;
                            }

                            @include MQ(XLSneg) {
                                @include fluid-type(1200px, 1600px, 22px, 26px);
                            }

                            @include MQ(XLneg) {
                                @include fluid-type(992px, 1600px, 18px, 22px);
                            }

                            @include MQ(Lneg) {
                                font-size: 2rem;
                                line-height: 1.4;
                                text-align: center;
                                padding: 0 0 35px;

                                span {
                                    display: inline-block;
                                }
                            }
                        }
                    }

                    .box-text {
                        width: calc(100% - 4vw);

                        @include MQ(XLneg) {
                            width: calc(100% - 2vw);
                        }

                        p {
                            font-size: 1.9rem;
                            line-height: 1.6;
                            margin-bottom: 25px;

                            @include MQ(XLSneg) {
                                @include fluid-type(1200px, 1600px, 16px, 19px);
                            }

                            @include MQ(XLneg) {
                                @include fluid-type(992px, 1200px, 14px, 16px);
                            }

                            @include MQ(Lneg) {
                                font-size: 1.6rem;
                                margin-bottom: 25px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }

                            &.underline {
                                position: relative;
                                padding-bottom: 7px;
                                display: inline-block;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    width: 100%;
                                    height: 2px;
                                    background: $primary;

                                }
                            }

                            strong.underline {
                                display: block;
                                position: relative;
                                text-decoration: underline;

                                @include MQ(Lneg) {
                                    display: inline-block;
                                }
                            }
                        }

                        a.box-text-link {
                            color: $secondary;
                            font-size: 1.9rem;
                            display: inline-block;
                            padding: 5px 0 20px 0;
                            width: auto;
                            transition: all .2s ease-in-out;
                            position: relative;

                            @include MQ(XLSneg) {
                                @include fluid-type(1200px, 1600px, 16px, 19px);
                            }

                            @include MQ(XLneg) {
                                @include fluid-type(992px, 1200px, 14px, 16px);
                            }

                            @include MQ(Lneg) {
                                font-size: 1.6rem;
                            }

                            &:after {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 2px;
                                background: $primary;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                transition: all .2s ease-in-out;
                            }
                        }

                        >ul {
                            margin-bottom: 25px;
                        }

                        ul {
                            list-style-type: disc;
                            list-style-position: inside;
                            width: 100%;

                            li {
                                font-size: 1.9rem;
                                margin-bottom: 4px;
                                width: 100%;

                                @include MQ(XLSneg) {
                                    font-size: 1.6rem;
                                    @include fluid-type(1200px, 1600px, 16px, 19px);
                                }

                                @include MQ(XLneg) {
                                    @include fluid-type(992px, 1200px, 14px, 16px);
                                }

                                @include MQ(Lneg) {
                                    font-size: 1.6rem;
                                }

                                &.sub-list {
                                    ul {
                                        list-style-type: circle;
                                        padding: 5px 0 0 25px;
                                    }
                                }
                            }
                        }

                        .modal-trigger {
                            margin-top: 25px;

                            button {
                                color: $secondary;
                                font-size: 1.9rem;
                                display: inline-block;
                                padding: 5px 0 15px 0;
                                width: auto;
                                transition: all .2s ease-in-out;
                                position: relative;
                                width: 175px;
                                text-align: left;
                                border-radius: 0;

                                @include MQ(XLSneg) {
                                    @include fluid-type(1200px, 1600px, 16px, 19px);
                                }
                                @include MQ(XLneg) {
                                    @include fluid-type(992px, 1200px, 14px, 16px);
                                }
                                @include MQ(Lneg) {
                                    width: 100%;
                                    font-size: 1.6rem;
                                }

                                .link-arrow {
                                    float: right;
                                    padding: 0 0 0 25px;
                                    transition: all .3s ease-in-out;

                                    img {
                                        margin-bottom: -3px;
                                    }
                                }

                                &:after {
                                    content: '';
                                    display: block;
                                    width: 100%;
                                    height: 2px;
                                    background: $primary;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    transition: all .2s ease-in-out;
                                }

                                &:hover {
                                    width: 200px;

                                    @include MQ(Lneg) {
                                        width: 100%;
                                    }
                                }
                            }

                            &--multiple {
                                margin-top: 25px;

                                .trigger-item {
                                    margin-bottom: 15px;
                                }

                                button {
                                    color: $secondary;
                                    font-size: 1.9rem;
                                    display: inline-block;
                                    padding: 5px 25px 15px 25px;
                                    width: auto;
                                    transition: all .2s ease-in-out;
                                    position: relative;
                                    width: 100%;
                                    text-align: left;
                                    border-radius: 0;
                                
                                    @include MQ(XLSneg) {
                                        @include fluid-type(1200px, 1600px, 16px, 19px);
                                    }
                                    @include MQ(XLneg) {
                                        @include fluid-type(992px, 1200px, 14px, 16px);
                                    }
                                    @include MQ(Lneg) {
                                        padding: 5px 0 15px;
                                        font-size: 1.6rem;
                                    }

                                    .link-arrow {
                                        float: right;
                                        padding: 0 0 0 25px;
                                        transition: all .2s ease-in-out;

                                        img {
                                            margin-bottom: -3px;
                                        }
                                    }

                                    &:after {
                                        content: '';
                                        display: block;
                                        width: 100%;
                                        height: 1px;
                                        background: $primary;
                                        position: absolute;
                                        bottom: 0;
                                        left: 0;
                                    }

                                    &:hover {
                                        .link-arrow {
                                            margin-right: -15px;
                                        }
                                    }
                                }
                            }
                        }

                        .box-text-additional {
                            max-width: 540px;

                            p {
                                font-size: 14px;
                                line-height: 19px;                                
                                @include MQ(XLSneg) {
                                    @include fluid-type(1200px, 1600px, 12px, 14px);
                                }
                                @include MQ(XLneg) {
                                    @include fluid-type(992px, 1200px, 10px, 12px);
                                }
                                @include MQ(Lneg) {
                                    font-size: 1.2rem;
                                }

                                .small {
                                    font-size: 1rem;
                                    line-height: 12px;
                                    display: block;
                                    padding-top: 10px;
                                }
                            }
                        }

                        .box-text-illustration {
                            margin: 50px 0;
                            position: relative;

                            &.img-magnifier-container {
                                position: relative;
                                &:hover {
                                    .img-magnifier-glass {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }

                            .img-magnifier-glass {
                                position: absolute;
                                border: 3px solid $primary;
                                cursor: none;
                                width: 200px;
                                height: 200px;
                                border-radius: 25px;
                                // width: 360px;
                                // height: 240px;
                                opacity: 0;
                                visibility: hidden;
                            }                            

                            @include MQ(Lneg) {
                                overflow-x: auto;
                            }

                            img {
                                max-width: 100%;
                                display: block;
                                margin: 0 auto;                               

                                @include MQ(Lneg) {
                                    max-width: unset;
                                }

                                &.max {
                                    @include MQ(Lneg) {
                                        max-width: 100%;
                                    }
                                }
                            }

                            p.small-annotation {
                                font-size: 10px;
                                line-height: 12px;
                                display: block;
                                padding: 10px 0;
                            }
                        }

                        .address-line {
                            @include MQ(Lneg) {
                                text-align: center;
                            }
                            a {
                                color: $primary;
                                font-size: 2rem;
                                display: inline-block;
                                margin: 0 0 25px;
                                @include MQ(XLSneg) {
                                    @include fluid-type(1200px, 1600px, 16px, 20px);
                                }
                                @include MQ(Lneg) {
                                    font-size: 2rem;
                                }
                            }
                            p {
                                strong span {
                                    @include MQ(Lneg) {
                                        display: block;
                                    }
                                }
                            }

                            .small {
                                font-size: 1.6rem;
                                @include MQ(XLSneg) {
                                    @include fluid-type(992px, 1600px, 14px, 16px);
                                }
                                @include MQ(Lneg) {
                                    font-size: 1.6rem;
                                    text-align: left;
                                }

                                &:first-child {
                                    margin-bottom: 25px;
                                }
                            }
                        }

                        .privacy-box {
                            padding: 0 0 64px;
                        }

                        .regulation-box {
                            padding: 64px 0;
                            @include MQ(Lneg) {
                                padding-top: 0;
                            }
                        }

                        .regulation-box,
                        .privacy-box {

                            .regulation-box-section,
                            .privacy-box-section {
                                p {
                                    font-size: 1.6rem;
                                    line-height: 26px;
                                    margin-bottom: 25px;                                    

                                    @include MQ(XLSneg) {
                                        @include fluid-type(992px, 1600px, 14px, 16px);
                                    }
                                    @include MQ(Lneg) {
                                        font-size: 1.6rem;
                                    }

                                    strong {
                                        display: block;
                                    }

                                    a {
                                        color: $primary;
                                    }
                                }
                            }
                        }
                    }

                    .box-key {
                        padding-top: 25px;
                        padding-bottom: 25px;

                        ul {
                            li {
                                font-size: 1.4rem;
                                @include fluid-type(580px, 1200px, 12px, 14px);
                                a {
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
            }

            .next-page {
                background: $secondary;

                .link-block {
                    padding: 40px 35px;
                    display: flex;
                    align-items: center;

                    &-icon {
                        padding-right: 25px;

                        img {
                            width: auto;
                            height: 38px;
                        }
                    }

                    &-text {
                        color: $white;
                        font-size: 1.7rem;
                        line-height: 1.4;
                        width: 100%;

                        h4 {
                            width: 75%;
                        }
                    }

                    &-arrow {
                        width: 21px;
                    }
                }
            }
        }
    }
}