.modal {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    transition: .3s all ease;
    &.modal-active {
        z-index: 9999;
        .modal-box {
            transform: translateX(0);
            opacity: 1;
            backface-visibility: visible;
        }
    }
    .modal-box {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 0 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparentize($secondary, .4);
        transform: translateX(100vw);
        opacity: 0;
        backface-visibility: hidden;
        transition: .3s all ease-in-out;
        @include MQ(XLneg) {
            left: 0 !important;
        }
        @include MQ(Lneg) {
            padding: 0;
        }
    }
    .modal-inner {
        width: 100%;
        .modal-inner-header {
            text-align: right;
            padding: 15px 0;
            @include MQ(Lneg) {
                padding: 15px;
            }
            img {
                width: 21px;
                cursor: pointer;
            }
        }
        .modal-inner-body {
            width: 100%;
            height: 100%;
            background: $white;
            position: relative;
            .modal-close {
                position: absolute;
                top: 25px;
                right: 25px;
                cursor: pointer;
                img {
                    width: 100%;
                    max-width: 26px;
                    @include MQ(XLneg) {
                        max-width: 21px;
                    }
                }
            }
            .block-content {
                padding: 25px 25px 25px 50px;
                width: calc(100% - 7vw);        
                height: 100%;
                overflow-y: auto;  
                @include MQ(MAXneg) {
                    height: calc(100vh - 100px);
                    padding-right: 4.5vw;
                }
                @include MQ(Lneg) {
                    padding: 0;
                    height: calc(100vh - 100px);
                    width: 100%;
                }
                &-header {
                    padding: 15px 0;
                    @include MQ(Lneg) {
                        padding: 25px 20px 15px;
                    }
                    h4 {
                        color: $primary;
                        font-size: 2.6rem;
                        line-height: 36px;
                        @include fluid-type(1200px, 1600px, 24px, 26px);
                        @include fluid-type(992px, 1200px, 18px, 24px);   
                        @include MQ(XLneg) {
                            line-height: 1.4;
                        }                   
                    }
                }
                &-body {
                    color: $secondary;
                    @include MQ(Lneg) {
                        width: 100%;
                        
                    }
                    p {
                        font-size: 1.9rem;
                        line-height: 30px;
                        @include fluid-type(1200px, 1600px, 17px, 19px);
                        @include fluid-type(992px, 1200px, 14px, 17px);
                        @include MQ(XLneg) {
                            line-height: 1.4;
                        }
                        @include MQ(Lneg) {
                            padding: 20px;
                        }
                    }
                    .image {
                        @include MQ(Lneg) {
                            width: 100vw;
                            overflow-x: auto;
                            padding: 20px 0;
                        }
                        img {
                            display: block;
                            margin: 0 auto;
                            padding: 35px 0;
                            max-width: 100%;
                            @include MQ(Lneg) {
                                max-width: unset;
                                padding: 25px;
                            }
                        }
                    }
                    ul.annotation {
                        @include MQ(Lneg) {
                            padding: 20px;
                        }
                        li {
                            font-size: 1.4rem;
                            line-height: 16px;
                            @include fluid-type(1200px, 1600px, 12px, 14px);
                            @include fluid-type(992px, 1200px, 10px, 12px);
                            @include MQ(XLneg) {
                                line-height: 1.2;
                            }
                        }
                    }
                }
            }
        }
    }
}