// #app {display:none;}
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $white;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    .preloader-container {
        background: $white;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .preloader-inner {
        position: relative;
        width: 320px;
        height: 125px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            content: '';
            position: absolute;
            bottom: 2px;
            left: 0;
            height: 8px;
            width: 100%;
            background: $secondary;
            animation: loading 1.5s ease-in-out infinite;
        }
        img {
            width: 100%;
        }
    }
}

@keyframes loading {
    from {
        left: -100%;
    }
    to {
        left: 100%;
    }
}