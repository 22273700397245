footer {

  .last-box {
    padding: 25px;
    background-color: rgba(blue, 20);
    margin-bottom: 65px;

    p {
      font-size: 4.8rem;
      line-height: 6rem;
      text-align: center;
      font-weight: 700;
      color: $slateGray;

      @include MQ(MAXneg){
        font-size: 4rem;
        line-height: 1.4;
      }

      @include MQ(XLneg){
        font-size: 3.5rem;
      }

      @include MQ(Lneg){
        font-size: 3rem;
      }

      @include MQ(Mneg){
        font-size: 2rem;
      }
    }
  }

  .copy {
    padding: 25px;
    background-color: lightBlue;

    p {
      font-size: 2rem;
      line-height: 2.9rem;
      color: $slateGray;
      text-align: center;

      @include MQ(MAXneg){
        line-height: 1.4;
      }

      @include MQ(XLneg){
        font-size: 1.8rem;
      }

      @include MQ(Lneg){
        font-size: 1.6rem;
      }

      @include MQ(Mneg){
        font-size: 1.4rem;
      }
    }
  }
}
