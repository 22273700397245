// --------------------------------------------------------------
// Preview Thumbnails
// --------------------------------------------------------------

$plyr-preview-padding: $plyr-tooltip-padding !default;
$plyr-preview-bg: $plyr-tooltip-bg !default;
$plyr-preview-radius: $plyr-tooltip-radius !default;
$plyr-preview-shadow: $plyr-tooltip-shadow !default;
$plyr-preview-arrow-size: $plyr-tooltip-arrow-size !default;
$plyr-preview-image-bg: $plyr-color-gray-2 !default;
$plyr-preview-time-font-size: $plyr-font-size-time !default;
$plyr-preview-time-padding: 3px 6px !default;
$plyr-preview-time-bg: rgba(0, 0, 0, 0.55);
$plyr-preview-time-color: #fff;
$plyr-preview-time-bottom-offset: 6px;

.plyr__preview-thumb {
    background-color: $plyr-preview-bg;
    border-radius: 3px;
    bottom: 100%;
    box-shadow: $plyr-preview-shadow;
    margin-bottom: $plyr-preview-padding * 2;
    opacity: 0;
    padding: $plyr-preview-radius;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 10px) scale(0.8);
    transform-origin: 50% 100%;
    transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
    z-index: 2;

    &--is-shown {
        opacity: 1;
        transform: translate(0, 0) scale(1);
    }

    // The background triangle
    &::before {
        border-left: $plyr-preview-arrow-size solid transparent;
        border-right: $plyr-preview-arrow-size solid transparent;
        border-top: $plyr-preview-arrow-size solid $plyr-preview-bg;
        bottom: -$plyr-preview-arrow-size;
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 0;
        z-index: 2;
    }

    &__image-container {
        background: $plyr-preview-image-bg;
        border-radius: ($plyr-preview-radius - 1px);
        overflow: hidden;
        position: relative;
        z-index: 0;

        img {
            height: 100%; // Non sprite images are 100%. Sprites will have their size applied by JavaScript
            left: 0;
            max-height: none;
            max-width: none;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    // Seek time text
    &__time-container {
        bottom: $plyr-preview-time-bottom-offset;
        left: 0;
        position: absolute;
        right: 0;
        white-space: nowrap;
        z-index: 3;

        span {
            background-color: $plyr-preview-time-bg;
            border-radius: ($plyr-preview-radius - 1px);
            color: $plyr-preview-time-color;
            font-size: $plyr-preview-time-font-size;
            padding: $plyr-preview-time-padding;
        }
    }
}

.plyr__preview-scrubbing {
    bottom: 0;
    filter: blur(1px);
    height: 100%;
    left: 0;
    margin: auto; // Required when video is different dimensions to container (e.g. fullscreen)
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.3s ease;
    width: 100%;
    z-index: 1;

    &--is-shown {
        opacity: 1;
    }

    img {
        height: 100%;
        left: 0;
        max-height: none;
        max-width: none;
        object-fit: contain;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
