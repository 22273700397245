.bars {
    background: $secondary;
    display: inline-block;
    padding: 8px 3px 0 3px;
}

.hamburger {
    &-box {
        .hamburger-inner {
            background-color: $white;
            height: 2px;
            &:before,
            &:after {
                background-color: $white;
                transition: .1s all ease-in-out;
                height: 2px;
            }
        }
    }
    &:hover {
        opacity: 1;
        @include MQ(Lplus) {
            .hamburger-inner:before {
                transform: scale(1.5, 1);
            }
        }
    }

    &.is-active {
        .hamburger-box {
            .hamburger-inner {
                background-color: $white;

                &:before,
                &:after {
                    background-color: $white;
                }
            }
        }
    }
}