//
// * {
//    outline: 1px solid black;
// }

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 62.5%;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    position: relative;
    min-width: $min-width;
    font-size: 2.2rem;
    line-height: 1.5;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
    color: $secondary;
    overflow-x: hidden;
    // overflow: hidden;

    &.disable-scroll {
        overflow: hidden;
        height: 100%;
    }
}

.frontpage {
    &.disable-scroll {
        overflow: hidden;
        height: 100%;
    }
}

h1,
h2,
h3,
h4 {}

h1 {
    font-size: 2.8rem;

    @include MQ(Mneg) {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
}

h2 {
    font-size: 2.4rem;
    font-weight: bold;

    @include MQ(Mneg) {
        font-size: 1.2rem;
        line-height: 2.2rem;
    }
}

h3 {}

h4 {}

p {}

a {
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &[href^="tel:"] {
        text-decoration: none;
    }

    &.link {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background-color: $white;
            transition: all .2s ease-out;
        }
    }
}

sup,
sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

strong {
    font-weight: 700;
}

button,
.button {
    border-radius: 4px;
    cursor: pointer;
    transition: opacity .3s ease-out;
}

input:focus {
    outline: none;
}


// animations
@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.8, 0.8, 0.8);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes popUpBg {
    from {
        opacity: 0;
        transform: scale3d(0.2, 0.2, 0.2);
    }

    50% {
        opacity: 1;
    }


    100% {
        opacity: 1;
    }
}

@keyframes popOut {
    from {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}