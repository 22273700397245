.abs {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    transition: all .5s ease-in-out;
    &.abs-active {
        visibility: visible;
        opacity: 1;
    }
}
.overlay-menu {
    width: 100%;
    height: 100%;
    background-image: url('../img/background/banner-menu@2x.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include MQ(Lneg) {
        background-image: none;
    }
    @include MQ(Mneg) {
        min-height: 100vh;
        overflow-y: auto;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparentize($secondary, 0);
        z-index: -1;
    }
}

.overlay-menu-header {
    height: 84px;
    @include MQ(Lneg) {
        height: 72px;
    }
    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 50px;
        @include MQ(Lneg) {
            padding: 0 25px;
            justify-content: flex-start;
            & > div {
                flex: 1 1 0;
                text-align: center;
                &:first-child {
                    text-align: left;
                    img {
                        width: 105px;
                    }
                }
                &:last-child {
                    margin-top: -5px;
                    text-align: right;
                    img {
                        width: 36px;
                    }
                }
            }
            & > .header-bars {
                .bars {
                    background: none;
                    .hamburger {
                        &.is-active {
                            .hamburger-inner {
                                transform: translate3d(0,0,0) rotate(45deg);
                                &:after {
                                    transform: translate3d(0,7px,0) rotate(-90deg);
                                }
                            }
                        }
                        &-box {
                            width: 32px;
                            height: 17px;
                            .hamburger-inner {
                                width: 28px;
                                height: 2px;
                                &:before, &:after {
                                    width: 28px;
                                    height: 2px;
                                }
                                &:before {top:7px;}
                                &:after {top:-7px;}
                            }
                        }
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.overlay-menu-body {
    width: 100%;
    height: calc(100% - (150px + 84px));
    display: flex;
    @include MQ(MAXneg) {
        height: calc(100% - (100px + 84px));
    }
    @include MQ(Lneg) {
        height: calc(100% - (72px + 72px));
    }
    @include MQ(Mneg) {
        height: 100%;
    }
    .body-wrapper {
        width: 100%;
        margin: auto 0;
        display: flex;
        justify-content: space-between;
        @include MQ(Lneg) {
            display: block;
        }
        .menu-column {
            a {
                color: $white;
                transition: all .2s ease-in-out;
                &:hover {
                    color: $primary;
                }
            }
            &--large, 
            &--small {
                flex: 1 1 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                @include MQ(Lneg) {
                    display: block;
                }
            }
            &--large {
                @include MQ(Lneg) {
                    margin-bottom: 20px;
                }
                li {
                    margin-bottom: 50px;
                    @include MQ(MAXneg) {
                        margin-bottom: 25px;
                    }
                    @include MQ(Lneg) {
                        margin-bottom: 15px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                a {
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    line-height: 44px;
                    @include MQ(MAXneg) {
                        line-height: 1.4;
                    }
                    @include MQ(Lneg) {
                        display: block;
                        text-align: center;
                        line-height: 34px;
                    }
                    @include MQ(Mneg) {
                        line-height: 27px;
                    }
                    .icon {
                        width: 100px;
                        margin-right: 15px;
                        @include MQ(Lneg) {
                            margin-right: 0;
                        }
                        img {
                            display: block;
                            margin: 0 auto;
                            max-height: 84px;
                            @include MQ(XLneg) {
                                max-height: 74px;
                            }
                            @include MQ(Lneg) {
                                max-height: 64px;
                                margin-bottom: 10px;
                            }
                            @include MQ(Mneg) {
                                max-height: 48px;
                            }
                            @include MQ(SMneg) {
                                max-height: 36px;
                            }
                        }
                    }
                    .text {
                        font-size: 3.4rem;
                        @include fluid-type(1200px, 1600px, 24px, 34px);
                        @include MQ(XLneg) {
                            line-height: 1.4;
                        }
                        @include MQ(Lneg) {
                            font-size: 2.8rem;
                            @include fluid-type(580px, 992px, 20px, 28px);
                        }
                        @include MQ(Mneg) {
                            font-size: 2rem;
                        }
                    }
                }
            }
            &--trigger {
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 15px;
                .bars {
                    background: $primary;
                    padding: 8px 3px 0 3px;
                    .hamburger-box {
                        transition: .2s all ease-in-out;
                    }
                    &:hover {
                        .hamburger-box {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            &--small {
                li {
                    margin-bottom: 5px;
                    @include MQ(Lneg) {
                        text-align: center;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                a {
                    position: relative;
                    padding-left: 60px;
                    @include fluid-type(1200px, 1600px, 18px, 22px);
                    @include fluid-type(992px, 1200px, 16px, 18px);
                    @include MQ(Lneg) {
                        font-size: 1.8rem;
                        line-height: 1.4;
                        padding-left: 0;
                        @include fluid-type(580px, 992px, 15px, 18px);
                        &:before {
                            display: none;
                        }
                    }
                    @include MQ(Mneg) {
                        font-size: 1.5rem;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 20px;
                        transform: translateY(-50%);
                        width: 25px;
                        height: 2px;
                        background: $white;
                    }
                }
            }
        }
    }
}

.overlay-menu-footer {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include MQ(MAXneg) {
        height: 100px;
    }
    @include MQ(Lneg) {
        height: 72px;
    }
    .copyright {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
        @include MQ(Lneg) {
            padding: 10px;
        }
        p.copy-small {
            font-size: 1.8rem;
            color: $white;
            text-align: center;
            @include fluid-type(580px, 1200px, 15px, 18px);
            @include MQ(Mneg) {
                font-size: 1.5rem;
            }
            a {             
                color: $white;      
                font-weight: 700;
                text-transform: uppercase;
                transition: all .2s ease-in-out;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}